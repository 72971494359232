<template>
  <footer>
    <div class="copyright">
      <div class="item">Copyright 2022</div>
      <div class="item">
        Dongba Culture Beijing ADCA
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "YFooter"
  }
</script>

<style scoped>
  .copyright {
    padding: 14px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #ffffff;
    background: #28292B;
    box-sizing: border-box;
  }
</style>
