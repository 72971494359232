export default {
  siteTitle: '东巴古籍数字化国际共享平台',
  title1: '北京东巴文化艺术发展促进会-ADCA',
  title2: '东巴古籍国际收藏机构',
  upname: '上传',
  back: '返回',
  login: {
    name: '登录',
    unameHolder: '用户名',
    pwdHolder: '请输入密码',
    submitText: '登录',
    noAccount: '暂无账号',
    toRegister: '立即注册'
  },
  register: {
    name: '注册',
    title: '用户注册',
    uname: '用户名',
    unameHolder: '请输入用户名',
    pwd: '设置密码',
    pwdHolder: "请设置密码",
    peopleName: '姓名',
    peopleHolder: '请输入姓名',
    confirmPwd: '确认密码',
    confirmHolder: '请再次输入密码',
    submitText: '立即注册',
    haveAccount: '已有账号',
    toLogin: '立即登录'
  },
  artDetail: {
    title: '数据详情',
    uploader: '上传者',
    pubTime: '发布时间',
    dataType: '数据类型',
    desc: '简介',
    dataDetail: '数据内容'
  },
  fileDetail: {
    title: '文件详情',
    fileName: '文件名称',
    fileDesc: '文件描述'
  },
  down: '下载'
}
