import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from '@/library/element-ui/lib'
import '@/library/element-ui/theme/index.css'
Vue.use(ElementUI)

import './styles/index.scss'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 0,
      play: {
        show: 0,
        size: 'large'
      },
      next: 0,
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 0,
      flipVertical: 0
    },
    title: 0
  }
})

import ellipsis from './utils/ellipsis/index'
Vue.use(ellipsis)

import './permission'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import cn from '@/assets/language/cn.js'
import en from '@/assets/language/en.js'
const i18n = new VueI18n({
  locale: 'en', // 语言标识
  messages: {
    'cn': {...cn}, // 中文语言包
    'en': {...en}, // 中文语言包
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
