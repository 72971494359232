import axios from './axios'

export function sendSmsCode(payload) {
  return axios.post('/api/public/sms/code', payload)
}

export function userLogin(payload) {
  return axios.post('/api/public/user/login', payload)
}

export function userLogout() {

}

export function userRegister(payload) {
  return axios.post('/api/public/user/registerv2', payload)
}

export function refreshUserToken(payload) {
  return axios.post('/api/public/user/token/refresh', payload)
}
