export default {
  siteTitle: 'International Digital Sharing Platform of Ancient Dongba Manuscripts',
  title1: 'Beijing Association of Dongba Culture and Arts (ADCA)',
  title2: 'Dongba Manuscript Collections International Agency',
  upname: 'Upload',
  back: 'Back',
  login: {
    name: 'Login',
    unameHolder: 'Input username',
    pwdHolder: 'Input password',
    submitText: 'Submit Login',
    noAccount: 'No Account',
    toRegister: 'To Register'
  },
  register: {
    name: 'Register',
    title: 'User Register',
    uname: 'Username',
    unameHolder: 'Input username',
    pwd: 'Password',
    pwdHolder: 'Input password',
    peopleName: "Name",
    peopleNameHolder: 'Input name',
    confirmPwd: 'Confirm password',
    confirmHolder: 'Confirm password',
    submitText: 'Submit Register',
    haveAccount: 'Already have account',
    toLogin: 'To Login'
  },
  artDetail: {
    title: 'Data Detail',
    uploader: 'Uploader',
    pubTime: 'Publish Time',
    dataType: 'Data Type',
    desc: 'Desc',
    dataDetail: 'Content'
  },
  fileDetail: {
    title: 'File Detail',
    fileName: 'File Name',
    fileDesc: 'Desc'
  },
  down: 'Download'
}
